@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Serif";
  src: url("fonts/SourceSerif4-ExtraBold.woff") format("woff"),
    url("fonts/SourceSerif4-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Serif";
  src: url("fonts/SourceSerif4-SemiBold.woff") format("woff"),
    url("fonts/SourceSerif4-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Serif";
  src: url("fonts/SourceSerif4-Medium.woff") format("woff"),
    url("fonts/SourceSerif4-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Serif";
  src: url("fonts/SourceSerif4-Regular_1.woff") format("woff"),
    url("fonts/SourceSerif4-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Staat";
  src: url("fonts/Staatliches-Regular.woff") format("woff"),
    url("fonts/Staatliches-Regular.ttf") format("truetype");
  font-weight: 400;
}

.textGradient {
  background: linear-gradient(
    93.93deg,
    #e3c151 6.78%,
    #ffd177 52.4%,
    #dd933b 93.86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  @apply font-['Serif'] font-normal text-2xl;
  background-image: url("images/button.webp");
  width: 293px;
  height: 50px;
  background-repeat: no-repeat;
  color: black;
  padding-left: 2.5rem;
  gap: 0.5rem;
  display: flex;
}

.jackpotAni1 {
  position: absolute;
  width: 44px;
  top: 0px;
  right: 8px;
  animation-name: jackpot;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.jackpotAni2 {
  position: absolute;
  top: 0px;
  left: 49px;
  animation-name: jackpot;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.jackpotAni3 {
  position: absolute;
  top: 0px;
  left: 0px;
  animation-name: jackpot;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes jackpot {
  0% {
    top: 0px;
  }

  100% {
    top: -238px;
  }
}

.revealRight {
  position: relative;
  transform: translateX(-150px);
  opacity: 0;
  transition: 1s all ease;
}
.revealLeft {
  position: relative;
  transform: translateX(150px);
  opacity: 0;
  transition: 1s all ease;
}
.revealLeft.active {
  transform: translateX(0);
  opacity: 1;
}

.revealRight.active {
  transform: translateX(0);
  opacity: 1;
}

.sticky-content {
  position: sticky;
  top: 0;
  z-index: 20;
}

.sticky-content-1 {
  position: sticky;
  top: 208px;
  z-index: 20;
}
